exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-about-founders-smartlabel-tsx": () => import("./../../../src/pages/blog/about-founders-smartlabel.tsx" /* webpackChunkName: "component---src-pages-blog-about-founders-smartlabel-tsx" */),
  "component---src-pages-blog-how-nfts-will-disrupt-music-industry-part-1-tsx": () => import("./../../../src/pages/blog/how-nfts-will-disrupt-music-industry-part1.tsx" /* webpackChunkName: "component---src-pages-blog-how-nfts-will-disrupt-music-industry-part-1-tsx" */),
  "component---src-pages-blog-how-nfts-will-disrupt-music-industry-part-2-tsx": () => import("./../../../src/pages/blog/how-nfts-will-disrupt-music-industry-part2.tsx" /* webpackChunkName: "component---src-pages-blog-how-nfts-will-disrupt-music-industry-part-2-tsx" */),
  "component---src-pages-blog-key-tips-emerging-musicians-artists-tsx": () => import("./../../../src/pages/blog/key-tips-emerging-musicians-artists.tsx" /* webpackChunkName: "component---src-pages-blog-key-tips-emerging-musicians-artists-tsx" */),
  "component---src-pages-blog-leveraging-nfts-independant-artists-tsx": () => import("./../../../src/pages/blog/leveraging-nfts-independant-artists.tsx" /* webpackChunkName: "component---src-pages-blog-leveraging-nfts-independant-artists-tsx" */),
  "component---src-pages-blog-revolutionary-web-3-start-up-tsx": () => import("./../../../src/pages/blog/revolutionary-web3-start-up.tsx" /* webpackChunkName: "component---src-pages-blog-revolutionary-web-3-start-up-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-blog-why-nfts-will-change-music-industry-tsx": () => import("./../../../src/pages/blog/why-nfts-will-change-music-industry.tsx" /* webpackChunkName: "component---src-pages-blog-why-nfts-will-change-music-industry-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-distribution-tsx": () => import("./../../../src/pages/distribution.tsx" /* webpackChunkName: "component---src-pages-distribution-tsx" */),
  "component---src-pages-distros-tsx": () => import("./../../../src/pages/distros.tsx" /* webpackChunkName: "component---src-pages-distros-tsx" */),
  "component---src-pages-exchange-tsx": () => import("./../../../src/pages/exchange.tsx" /* webpackChunkName: "component---src-pages-exchange-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invest-tsx": () => import("./../../../src/pages/invest.tsx" /* webpackChunkName: "component---src-pages-invest-tsx" */),
  "component---src-pages-paperwork-tsx": () => import("./../../../src/pages/paperwork.tsx" /* webpackChunkName: "component---src-pages-paperwork-tsx" */),
  "component---src-pages-pitch-tsx": () => import("./../../../src/pages/pitch.tsx" /* webpackChunkName: "component---src-pages-pitch-tsx" */),
  "component---src-pages-press-news-founder-of-thc-becomes-ambassador-tsx": () => import("./../../../src/pages/press/news-founder-of-thc-becomes-ambassador.tsx" /* webpackChunkName: "component---src-pages-press-news-founder-of-thc-becomes-ambassador-tsx" */),
  "component---src-pages-press-news-smartlabel-music-of-the-future-tsx": () => import("./../../../src/pages/press/news-smartlabel-music-of-the-future.tsx" /* webpackChunkName: "component---src-pages-press-news-smartlabel-music-of-the-future-tsx" */),
  "component---src-pages-press-news-traxs-makes-music-streaming-more-fun-tsx": () => import("./../../../src/pages/press/news-traxs-makes-music-streaming-more-fun.tsx" /* webpackChunkName: "component---src-pages-press-news-traxs-makes-music-streaming-more-fun-tsx" */),
  "component---src-pages-press-news-traxs-music-streaming-service-demo-tsx": () => import("./../../../src/pages/press/news-traxs-music-streaming-service-demo.tsx" /* webpackChunkName: "component---src-pages-press-news-traxs-music-streaming-service-demo-tsx" */),
  "component---src-pages-press-news-traxs-takes-artists-seriously-tsx": () => import("./../../../src/pages/press/news-traxs-takes-artists-seriously.tsx" /* webpackChunkName: "component---src-pages-press-news-traxs-takes-artists-seriously-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-profiles-jonathan-tsx": () => import("./../../../src/pages/profiles/jonathan.tsx" /* webpackChunkName: "component---src-pages-profiles-jonathan-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-whitepaper-tsx": () => import("./../../../src/pages/whitepaper.tsx" /* webpackChunkName: "component---src-pages-whitepaper-tsx" */)
}

